import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export default () => {
    const gsapTimeline = async () => gsap.timeline();
    const gsapTo = (element, options) => gsap.to(element.value || element, options);
    const gsapScrollTo = (element, options) => {
        gsap.to(element.value || element, options);
    };
    const gsapSet = (element, options) => gsap.set(element.value || element, options);
    const gsapFrom = (element, options) => gsap.from(element.value || element, options);
    const gsapFromTo = (elements, from, options) => {
        gsap.fromTo(elements._rawValue || elements, from, options);
    };

    return {
        gsapTimeline,
        gsapTo,
        gsapScrollTo,
        gsapSet,
        gsapFrom,
        gsapFromTo,
    };
};
